
.body-BG{
  background-color: #F0F3FB;
  height: 100vh;
}
.navbar{
  width: 100%;
  height: 5rem;
  background-color: #CB8B65;
  justify-content: center;
  
}

.left{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-left: 2rem;

}

.right{
  margin-right: 0rem;

}

.right .create-btn{
  background-color: #FFDB7E;
  border: 0;
  border-radius: 5px;
  width: 140px;
  height: 2.5rem;
  box-shadow: 6px 6px 2px rgb(0, 0, 0,.1);

}



.right{
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
}

.main{
  margin-left: 2rem;
}

.bar{
  /* width: 80%; */
  height: 4rem;
  background-color: #ffffff;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 10px;
  /* margin-top: 2rem; */
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: space-between;
  box-shadow: 0px 4px 4px 2px rgb(231, 231, 231); 
}

.bar span{
  color: #CB8B65;
  font-size: 1.2rem;
  font-weight: 600;
}

.search button{
  /* background-color: #CB8B65; */
  background-color: white;
  border: 0;
}

/* .search :hover{
  background-color: #CB8B65;
} */

.container-2{
  width: 100%;
  /* background-color: #ffffff; */
  /* border: 1px solid rgb(228, 228, 228); */
  /* border-radius: 10px; */
  margin-top: 2rem;
  display: flex;
  align-items: center;
  /* padding: 2rem; */
  justify-content: space-between;
  /* box-shadow: 0px 4px 4px 2px rgb(231, 231, 231);  */
}


.total-student-box{

  /* width: 20rem; */
  height: 8rem;
  /* background-color: #6A73FA; */
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 2rem;
  gap: 2rem;
}

.total-teacher-box{

  width: 20rem;
  height: 12rem;
  /* background-color: #22C04F; */
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 2rem;
  gap: 2rem;
}
.direct-post{

  width: 20rem;
  height: 12rem;
  /* background-color: #FF1616; */
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 2rem;
  gap: 2rem;
}
#student-icon{
  background-color: white;
  padding: 1rem;
  border-radius: 100%;
  width: 4rem;
  height: 4rem;

}

.total-student-text{

  color: white;
  display: flex;
  flex-direction: column;
  gap: .2rem;
  
}

.number-of-students{
  font-size: 2rem;
  font-weight: 600;

}

.para{
  font-size: 1rem;
  margin-top: .6rem;
}

.bg-progress-bg-1{

  background-color: #494e8a !important;
  
}

.bg-progress-bg-2{

  background-color: #208d3f !important;
  
}
.bg-progress-bg-3{

  background-color: #971616 !important;
  
}

.profile{
 
  background-color: rgb(90, 96, 114);
  border-radius: 100%;
  padding-left: 1.1rem;
  padding-top: .6rem;
  background-color: rgb(228, 237, 240);
  width: 53px;
  font-size: 1.5rem;
  height: 3.5rem;

}
